// export const SERVER_API_URL = 'http://localhost:8081/api/';
// // export const UPLOAD_API_URL = '';
// export const UPLOAD_API_URL = 'http://localhost:8081/api/';
// export const SYNCFUSION_COMMUNITY_KEY =
//   'Ngo9BigBOggjHTQxAR8/V1NHaF5cXmpCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9fdnVTQ2VcUEZxVkQ=';
// export const SOCKET_URL = 'ws://localhost:8081';



// PROD

export const SERVER_API_URL = 'https://api.viinamillk.online/api/';
// export const UPLOAD_API_URL = '';
export const UPLOAD_API_URL = 'https://api.viinamillk.online/api/';
export const SYNCFUSION_COMMUNITY_KEY =
  'Ngo9BigBOggjHTQxAR8/V1NHaF5cXmpCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9fdnVTQ2VcUEZxVkQ=';
export const SOCKET_URL = 'https://api.viinamillk.online';