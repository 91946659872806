import avatar from '@/assets/img/avatar.jpg';
import ArrowNarrowLeftIcon from '@/components/shared/icons/ArrowNarrowLeftIcon';
import EditIcon from '@/components/shared/icons/EditIcon';
import SearchIcon from '@/components/shared/icons/SearchIcon';
import { RootState } from '@/reducers';
import { socket } from '@/shared/config/socket';
import { IChat } from '@/shared/model/chat.model';
import { IUser } from '@/shared/model/user.model';
import { useRouter } from '@/shared/utils/hooks/useRouter';
import { getTruncateTxt } from '@/shared/utils/ultils';
import { AppDispatch } from '@/store';
import { CAvatar, CCol, CFormInput, CInputGroup, CInputGroupText, CRow } from '@coreui/react-pro';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEntities } from './chat.api';
import { chatSelectors, fetching, initialChatFilter, setFilterState } from './chat.reducer';
import CreateChat from './CreateChat';
import Message from './Message/Message';
import { toggleMessageInfo } from './Message/message.reducer';
import avatar1 from '@/assets/img/images.png'
import { IMessage } from '@/shared/model/message.model';

export const handleChatRoomName = (room: IChat, user: IUser | null, maxLength = 30) => {
  if (!user) return '';

  const joinUsers = room.users.filter((item) => item.id !== user.id);
  const roomName = joinUsers.map((user) => user.fullName || user.username).join(', ');

  return getTruncateTxt(roomName, maxLength);
};

const Chat = () => {
  const { navigate, params } = useRouter();
  const { roomId } = params;
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.authentication);
  const { initialState } = useSelector((state: RootState) => state.chatReducer);
  const { filterState, totalPages } = initialState;
  const { bodyWidth } = useSelector((state: RootState) => state.container);
  const [createChatVisible, setCreateChatVisible] = useState<boolean>(false);
  const [searchMode, setSearchMode] = useState<boolean>(false);
  const [searchMsgKey, setSearchMsgKey] = useState<string>('');

  const listChatRef = useRef<HTMLDivElement>(null);

  const chatRooms = useSelector(chatSelectors.selectAll);

  const handleCreateChat = () => {
    dispatch(toggleMessageInfo(false));
    setCreateChatVisible(true);
  };

  useEffect(() => {
    const fetchData = () => {
      dispatch(fetching());
      dispatch(getEntities(filterState));
    };

    fetchData();

    socket.on(`user_${user?.id}_new_message`, fetchData);

    return () => {
      socket.off(`user_${user?.id}_new_message`, fetchData);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState)]);

  useEffect(() => {
    if (searchMsgKey.trim()) {
      const refetchTimer = setTimeout(() => {
        dispatch(setFilterState({ ...filterState, key: searchMsgKey.trim() }));
      }, 1000);
      return () => clearTimeout(refetchTimer);
    } else {
      dispatch(setFilterState(initialChatFilter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMsgKey]);

  useEffect(() => {
    const handleScroll = () => {
      const div = listChatRef.current;
      if (!div) return;

      if (div.scrollTop + div.clientHeight === div.scrollHeight) {
        console.log('Đã đến cuối list room chat');
        if (totalPages > 1) dispatch(setFilterState({ ...filterState, limit: filterState.limit + 20 }));
      }
    };

    const div = listChatRef.current;
    if (!div) return;
    div.addEventListener('scroll', handleScroll);

    return () => {
      div.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState), totalPages]);

  useEffect(() => {
    return () => {
      setSearchMode(false);
      setSearchMsgKey('');
      dispatch(setFilterState(initialChatFilter));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderId = (room: IChat) => {
    return room.users.filter( (i) => i.id !== user?.id ).map((i) => i.id).join(',')
  }

  return (
    <div className="chat-container position-fixed" style={{ width: bodyWidth }}>
      <CRow className="m-0 ">
        <CCol xs={12} md={3} className="list-chat-container custom-form">
          <div className={`chat-info-header ${searchMode ? 'flex-nowrap' : 'flex-wrap'}`}>
            {searchMode ? (
              <>
                <ArrowNarrowLeftIcon
                  className="me-2 cursor-pointer"
                  onClick={() => {
                    setSearchMode(false);
                    setSearchMsgKey('');
                  }}
                />
                <CInputGroup className="input-start-group">
                  <CInputGroupText id="search-addon" className="cursor-pointer">
                    <SearchIcon height={16} width={16} />
                  </CInputGroupText>
                  <CFormInput
                    placeholder="Tìm kiếm"
                    value={searchMsgKey}
                    name="key"
                    onChange={(e) => setSearchMsgKey(e.currentTarget.value)}
                  />
                </CInputGroup>
              </>
            ) : (
              <>
                <span className="p-2" onClick={handleCreateChat}>
                  <EditIcon />
                  Tạo mới
                </span>
                <span className="p-2" onClick={() => setSearchMode(true)}>
                  <SearchIcon height={16} width={16} />
                  Tìm kiếm
                </span>
              </>
            )}
          </div>
          <div className="list-chat d-flex d-md-block" ref={listChatRef}>
            {chatRooms.map((room) => (
              <div
                key={`chat-${room.id}`}
                className={`chat-room ${Number(roomId) === room.id ? 'active' : ''}`}
                onClick={() => navigate(`/admin/message/${room.id}`)}
              >
                <div className="d-flex align-items-center me-md-12">
                  <CAvatar src={room.avatar || avatar1} className="avatar-40" />
                </div>
                <div className="d-none d-md-block w-100 ms-12">
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <span className="chat-room-name">{renderId(room)} - {handleChatRoomName(room, user)}</span>
                    <span className="text-xs text-gray-modern-400">
                      {dayjs(room.latestMessage?.timestamp || room.createdDate)
                        .fromNow()
                        .replaceAll('một', '1')}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="text-xs text-gray-modern-400">
                      {getTruncateTxt(room.latestMessage?.content, 30) || 'Đang cập nhật...'}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </CCol>
        <CCol xs={12} md={9} className="p-0">
          <Message />
        </CCol>
      </CRow>

      {createChatVisible ? <CreateChat visible={createChatVisible} setVisible={setCreateChatVisible} /> : null}
    </div>
  );
};

export default Chat;