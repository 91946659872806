import { SVGProps } from "react";

const BIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
      data-testid="GppGoodIcon"
      width={props?.width || 24}
      height={props?.height || 24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M12 2L4 5v6.09c0 5.05 3.41 9.76 8 10.91 4.59-1.15 8-5.86 8-10.91V5l-8-3zm-1.06 13.54L7.4 12l1.41-1.41 2.12 2.12 4.24-4.24 1.41 1.41-5.64 5.66z"></path>
    </svg>
  );
}

export default BIcon;
